const getPageTitle = (page) => {
    const titles = {
        '/': 'Login to Deon',
        '/dashboard': 'Dashboard | Deon',
        '/users': 'Users | Deon',
        '/sectors': 'Sectors | Deon',
        '/companies': 'Companies | Deon',
        '/bonds': 'Bonds | Deon',
        '/bonds/create-bonds': 'Add Bonds | Deon',
        '/hoot': 'Hoot | Deon',
        '/chat': 'Chat | Deon',
        '/change-password': 'Change Password | Deon',
        '/users/[id]': 'Profile | Deon',
        '/bonds/[bondId]': 'DES | Deon',
        '/matchings': 'Matching | Deon',
        '/matchings/[id]/history': 'View Stats | Deon',
        '/matchings/[id]': 'View Matching | Deon',
        '/recent': 'Recent Bonds | Deon',
        '/sectors/interest': 'Sectors | Deon',
        '/termandcondition': 'Term and Condition | Deon'
    };
    return titles[page] || 'Deon';
};
export default getPageTitle;
