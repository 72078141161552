import PropTypes from 'prop-types';
import { Search } from 'react-feather';
import InputBox from './InputBox';

const SearchBox = ({
    register,
    className,
    inputClass,
    name,
    placeholder,
    onChange,
    icon,
    reference,
    iconClass,
    bgColor,
    onClearSearch,
    ...rest
}) => (
    <div className={`relative ${className}`}>

        <InputBox
            bgColor={bgColor || 'bg-primary-400'}
            iconClass={`top-1/2 -translate-y-1/2 ${iconClass}`}
            name="search"
            type="text"
            placeholder={placeholder}
            className={`text-gray-100 font-normal text-sm h-9 leading-9 ${inputClass}`}
            {...(register && { ...register(name) })}
            iconComponent={icon || <Search size={20} />}
            onChange={onChange}
            reference={reference}
            autoComplete="off"
            isCloseIcon={false}
            onClearSearch={onClearSearch}
            {...rest}

        />
    </div>
);

SearchBox.propTypes = {
    register: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    iconClass: PropTypes.string,
    className: PropTypes.string,
    bgColor: PropTypes.string
};

export default SearchBox;
