import { useUser } from '@auth0/nextjs-auth0';
import Loader from 'atoms/Loader';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
    const { user, isLoading } = useUser();

    const unProtectedRoutes = ['/'];

    let isProtected = !unProtectedRoutes.includes(router.pathname);
    isProtected = !unProtectedRoutes.some(prefix => router.pathname.startsWith(prefix));

    if (isLoading) {
        return <Loader />;
    }

    if (isBrowser() && isProtected && (!user)) {
        router.replace('/');
        return null;
    }
    return children;
};

export default WithAuth;
